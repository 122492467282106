
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class EditUserModal extends Vue {

    @Prop({
        default: () => new OM.InternalUserEditVM()
    }) model: OM.InternalUserEditVM;
    localModel: OM.InternalUserEditVM = new OM.InternalUserEditVM();

    @Prop() callback: any;

    created() {
        this.localModel = JSON.parse(JSON.stringify(this.model));
        if(!this.localModel.preferredCulture)
            this.localModel.preferredCulture = "it-IT";
    }

    save() {
        this.callback(this.localModel);
    }

}


import { Options, Vue } from 'vue-class-component';
import { InternalUserClient } from '@/services/Services';
import EditUserModal from '../modal/editUserModal.vue';
import * as OM from '@/Model';

@Options({})
export default class Users extends Vue {

    internalUsers: OM.InternalUserEditVM[] = [];

    created() {
        this.init();
    }

    init() {
        InternalUserClient.getAllInternalUser()
        .then(x => {
            this.internalUsers = x;
        })
    }

    filtersFn = {
        'nameDesc': (propVal: string, filterVal: string, item: OM.InternalUserEditVM) => {
            return item.name.toLowerCase().indexOf(filterVal.toLowerCase()) > -1;
        }
    }

    editUser(user: OM.InternalUserEditVM) {
        if(user == null)
            user = new OM.InternalUserEditVM();

        this.$opModal.show(EditUserModal, {
            model: user,
            callback: (updatedUser: OM.InternalUserEditVM) => {
                InternalUserClient.editInternalUser(updatedUser)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

}
